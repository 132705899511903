import React, { useEffect, useState } from "react";
import "../../assets/scss/booking.scss";
import "../../assets/scss/tabs-banner.scss";
import infoIcon from "../../assets/svg/info-icon.svg";
import Popupinfo from "../../components/booking/Popupinfo";


import DatePicker from "react-datepicker";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/scss/modify_datepicker.scss";
import MapQuestDirections from "./MapQuestMap";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';

import Loader from "../../components/loading/loader"
import moment from "moment";
import { getLocations } from "../../external_api/Location";
import { getReservationDetail, getVehicleAvailable, saveBooking, updateBooking } from "../../external_api/Booking";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/booking/Header";
import Titlestrip from "../../utility/Titlestrip";

import { paymentTypeList, paymentTypeRateList } from "../../constants/StaticData";


const CustomerInformation = () => {
  const { isFetching, isSuccess: isLogin } = useSelector(
    userSelector
  );
  const navigate = useNavigate();
  if (!isLogin) {
    navigate("/");
  }

  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);
  

  const [vPopup, setVPopup] = useState(false);
  const closeHandler = () => {
    setVPopup(false);
  };

  
  const validationSchema = Yup.object().shape({
    /* firstname: Yup.string()
      .required('First name is required'),
    lastname: Yup.string()
      .required('Last name is required'),
     */
    phone: Yup.string()
           .transform((value) => (value === null ? '' : value)),
    email: Yup.string()
      .email()
      .required('Email is required'),
    airline_carrier: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .max(3,"Airline Carrier must be at most 3 characters"),
    flight_number: Yup.string()
    .transform((value) => (value === null ? '' : value))
    .max(4, "Flight Number must be at most 4 characters"),
    driving_licence_number: Yup.string(),
    driving_licence_expiration: Yup.string(),
    gpnumber: Yup.string(),
    customer_remarks: Yup.string().nullable(),
    payment_type: Yup.string().nullable(),
  });

  type FormValues = {
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    airline_carrier: string,
    flight_number: string,
    driving_licence_number: string,
    dropoff_oagcode: string,
    driving_licence_expiration: string | null,
    gpnumber: string,
    customer_remarks: string,
    payment_type?:string
  }

  const { register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    control
  } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });

  console.log("booking errors ", errors);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [selExipryDate, SetSelExipryDate] = useState<any>(null)

  const [customerDefaultData, setCustomerDefaultData] = useState(
    {
      firstname: "",
      lastname: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      airline_carrier: "",
      flight_number: "",
      driving_licence_number: "",
      dropoff_oagcode: "",
      driving_licence_expiration: "",
      gpnumber: "",
      customer_remarks: "",
      payment_type: "",

    }
  );

  const selectedVehicle = String(localStorage.getItem('selected_vehicle'));
  var selectedVehicelObj:any = {};
  if(selectedVehicle) {
    selectedVehicelObj = JSON.parse(selectedVehicle);
  }
 

  const onSubmitForm = async (formData: any) => {
    setServerError("");
    //console.log("formData", formData)
    //return true;
    formData['reservation_id'] = reservationId;
    const searchBooking = String(localStorage.getItem('search_booking'));
    const searchBookingObj = JSON.parse(searchBooking); 
    formData['search_booking'] = searchBookingObj;
    const selectedVehicle = String(localStorage.getItem('selected_vehicle'));
    const selectedVehicleObj = JSON.parse(selectedVehicle);
    formData['selected_vehicle'] = selectedVehicleObj;
    
    console.log("Booking formData", formData);
    
    setLoading(true);
    var response = await updateBooking(formData);
    const { data, status, errorMessage } = response;
    console.log("reservation response data", data);
    if (status == 201) {
      setLoading(false);
      localStorage.setItem('booked_reservation_id', data['reservationId'])
      setTimeout(() => {
        navigate("/booking/confirmation");
      }, 500);
    } else {
      if (errorMessage.errors) {
        if(errorMessage.errors.ShortText === "NM- MODIFICATION NOT ALLOWED ID- PRESENT") {
          setServerError("Name entered doesn't match name on profile");
        } else {
          setServerError(errorMessage.errors.ShortText);
        }
        //toast.error(errorMessage.errors.ShortText);
      } else {
        //toast.error(errorMessage);
        setServerError(errorMessage);
      }

    }
    setLoading(false);

  };  


  useEffect(() => {
    let exipryDate = selExipryDate ? moment(new Date(selExipryDate)).format("YYYY-MM-DD") : "";
    setValue("driving_licence_expiration",exipryDate); 
    
  }, [selExipryDate])


  useEffect(() => {
    (async () => {
        setLoading(true);
        var response = await getReservationDetail(String(reservationId));
        var { data, status } = response;
        
        if (status === 201) {
            setTimeout(() => setCustomerDefaultData(data.reservation), 500);
        } else {
            navigate("/booking");
        }
        setLoading(false);

        //console.log("profile data",data.output);
    })()

}, []);

const [selPaymentType, SetSelPaymentType] = useState<any>("");
const [paymentType, setPaymentType] = useState("");
useEffect(() => {
        console.log("customerDefaultData", customerDefaultData)
    if(customerDefaultData.driving_licence_expiration) {
        const exipryDate = new Date(customerDefaultData.driving_licence_expiration);
        selExipryDate(exipryDate);
    }


      var payment_type_element = document.getElementsByName("payment_type");
      //if(typeof(payment_type_element) == 'undefined' && payment_type_element != null)
      if(payment_type_element.length > 0)
      {
        //@ts-ignore
        setPaymentType(document.getElementsByName("payment_type")[0].value);
         //@ts-ignore
        console.log("payment_type value:",document.getElementsByName("payment_type")[0].value)
      
      // Field exists
      }
      

    /* //@ts-ignore
    if(document.getElementsByName("payment_type")[0].value) {
      //@ts-ignore
      setPaymentType(document.getElementsByName("payment_type")[0].value);
    } */
    
    const paymentType = paymentTypeList.filter(function(pt){
          if(pt.code === customerDefaultData.payment_type.toUpperCase()) {
              SetSelPaymentType(pt);
              return true;
          } 
          
    })

        
     return reset(
         {
            firstname: customerDefaultData.first_name,
            lastname: customerDefaultData.last_name,
            phone: customerDefaultData.phone,
            email: customerDefaultData.email,
            airline_carrier: customerDefaultData.airline_carrier,
            flight_number: customerDefaultData.flight_number,
            driving_licence_number: customerDefaultData.driving_licence_number,
            dropoff_oagcode: customerDefaultData.dropoff_oagcode,
            driving_licence_expiration: customerDefaultData.driving_licence_expiration,
            gpnumber: customerDefaultData.gpnumber,
            customer_remarks: customerDefaultData.customer_remarks,
            payment_type: customerDefaultData.payment_type,
             
         }
     );
 }, [reset, customerDefaultData]);


  return (
    <>
    <Toaster
        toastOptions={{
          className: 'toaster-popup',
        }}
      />
      {loading ? (

        <Loader />
      ) :
        ""
      }
      <Header paymentType={paymentType} />
      <Titlestrip title={"Edit Reservation : "+reservationId} />
      <section className="container-fluid wiz-form-hertz">
        <div className="row">
          <div className="container">
            <div className="container-with-border">
              <div className="tab-hertz">
                <ul className="list-inline d-flex justify-content-between tab-list-hertz">
                  <li className="list-inline-item active completeProcess">
                    <button type="button" onClick={(e) =>navigate("/booking/edit/"+reservationId)}>SEARCH</button>
                  </li>
                  <li className="list-inline-item active completeProcess">
                    <button type="button" onClick={(e) =>navigate("/booking/vehicle-list/edit/"+reservationId)}>RESULT</button>
                  </li>
                  <li className="list-inline-item active">
                    <button type="button">CUSTOMER INFORMATION</button>
                  </li>
                </ul>
                <div className="tab-wiz-inner-hertz">
                  <div
                    className="tab-inner-hertz customerInfo"
                    style={{ display: "block" }}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="serverErrorContainer">
                            {
                              serverError ? 
                            <div
                              className="server-error"
                              style={{ display: "block" }}
                            >
                              {serverError}
                              {" "}
                            </div>
                            :
                            ""
                            }
                          </div>
                          <form
                          className="spacertopform"
                          onSubmit={handleSubmit(onSubmitForm)}
                          name="customerForm"
                          id="customerForm"
                          autoComplete="off"
                          >
                            <div className="inner-tab-hertz-content">
                              {/* <h2>Customer Information</h2> */}
                              <div className="main-form-hertz">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="containerbod promotion-hertz">
                                      <label className="label-space-6">
                                        First Name{" "}
                                        <span className="mandatory">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        {...register("firstname")}
                                        className="input-field"
                                        disabled={true}
                                      />
                                    </div>
                                    <span className="errorLabel">{errors.firstname?.message}</span>
                                  </div>

                                  <div className="col-6">
                                    <div className="containerbod promotion-hertz">
                                      <label className="label-space-6">
                                        Last Name{" "}
                                        <span className="mandatory">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        {...register("lastname")}
                                        className="input-field"
                                        disabled={true}
                                      />
                                    </div>
                                    <span className="errorLabel">{errors.lastname?.message}</span>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Passenger Phone
                                          </label>
                                          <input
                                            type="text"
                                            {...register("phone")}
                                            className="input-field"
                                          />
                                        </div>
                                        <span className="errorLabel">{errors.phone?.message}</span>
                                      </div>

                                      <div className="col-md-6 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Email
                                            <span className="mandatory">*</span>
                                          </label>
                                          <input
                                            type="text"
                                            {...register("email")}
                                            className="input-field"
                                          />
                                        </div>
                                        <span className="errorLabel">{errors.email?.message}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-12">
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Carrier{" "}
                                          </label>
                                          <input
                                            type="text"
                                            {...register("airline_carrier")}
                                            className="input-field"
                                          />
                                          {/*
                                          <select
                                            {...register("airline_carrier")}
                                            className="select-field dropLocation"
                                          >
                                            <option value=""></option>
                                            <option value="AUH">Abu Dabi</option>
                                            <option value="JP">Adria Airways</option>
                                            <option value="A3">Aegean Airlines</option>
                                            <option value="NN">Aer Arann</option>
                                            <option value="EI">Aer Lingus</option>
                                            <option value="D9">Aero Flot Donavia</option>
                                            <option value="YP">Aero Lloyd</option>
                                            <option value="OT">Aero Pelican</option>
                                            <option value="P5">Aero República</option>
                                            <option value="SU">Aeroflot Russia Airlines</option>
                                            <option value="AR">Aerolineas Argentinas</option>
                                            <option value="AM">Aeromexico</option>
                                            <option value="VH">Aeropostal</option>
                                            <option value="VV">AeroSvit Ukrainian Airlines</option>
                                            <option value="G9">Air Arabia</option>
                                            <option value="YES">Air Arabia</option>
                                            <option value="ARR">Air Armenia</option>
                                            <option value="AK">Air Asia</option>
                                            <option value="UU">Air Austral (Reunion Island)</option>
                                            <option value="BT">Air Baltic</option>
                                            <option value="AB">Air Berlin</option>
                                            <option value="SB">Air Calin</option>
                                            <option value="AC">Air Canada</option>
                                            <option value="TX">Air Caraibes</option>
                                            <option value="CA">Air China</option>
                                            <option value="PE">Air Europe</option>
                                            <option value="AF">Air France</option>
                                            <option value="AI">Air India</option>
                                            <option value="JM">Air Jamaica</option>
                                            <option value="FU">Air Littoral</option>
                                            <option value="KM">Air Malta</option>
                                            <option value="MK">Air Mauritius</option>
                                            <option value="NZ">Air New Zealand</option>
                                            <option value="PX">Air Niugini</option>
                                            <option value="FJ">Air Pacific</option>
                                            <option value="APN">Air Polonia</option>
                                            <option value="TN">Air Tahiti Nui</option>
                                            <option value="TS">Air Transat</option>
                                            <option value="NF">Air Vanuatu</option>
                                            <option value="UT">Aircompany "UTAIR"</option>
                                            <option value="OP">AirOne</option>
                                            <option value="AS">Alaska Airlines</option>
                                            <option value="AZ">Alitalia Airlines</option>
                                            <option value="NH">All Nippon Airways</option>
                                            <option value="G4">Allegiant Air</option>
                                            <option value="QQ">Alliance Airlines</option>
                                            <option value="UJ">Almasria Universal Airlines</option>
                                            <option value="HP">America West</option>
                                            <option value="AA">American Airlines</option>
                                            <option value="2Z">Amtrak</option>
                                            <option value="IZ">Arkia Israeli Airlines</option>
                                            <option value="R7">Aserea Airlines</option>
                                            <option value="OZ">Asiana Airlines</option>
                                            <option value="EV">Atlantic Southeast Airlines</option>
                                            <option value="GR">Aurigny Airlines</option>
                                            <option value="AO">Australian Airlines</option>
                                            <option value="OS">Austrian Airlines</option>
                                            <option value="6A">AVIACSA</option>
                                            <option value="AV">Avianca</option>
                                            <option value="U3">Avies</option>
                                            <option value="9V">Avior Airlines</option>
                                            <option value="AD">Azul</option>
                                            <option value="UP">Bahama Air</option>
                                            <option value="B2">Belavia-Belarusian Airlines</option>
                                            <option value="KF">Blue1</option>
                                            <option value="BD">bmi</option>
                                            <option value="WW">bmibaby</option>
                                            <option value="BU">Braathens</option>
                                            <option value="FQ">Brindabella Airlines</option>
                                            <option value="BA">British Airways</option>
                                            <option value="SN">Brussels Airlines</option>
                                            <option value="5T">Canadian North</option>
                                            <option value="C6">CanJet</option>
                                            <option value="9K">Cape Air</option>
                                            <option value="BW">Caribbean Airlines</option>
                                            <option value="CX">Cathay Pacific Airways</option>
                                            <option value="KX">Cayman Airways</option>
                                            <option value="CI">China Airlines</option>
                                            <option value="MU">China Eastern Airlines</option>
                                            <option value="CZ">China Southern Airlines</option>
                                            <option value="QI">Cimber Sterling</option>
                                            <option value="C9">Cirrus Airline</option>
                                            <option value="CF">City Airlines</option>
                                            <option value="WX">CityJet</option>
                                            <option value="BX">Coast Air AS</option>
                                            <option value="DE">Condor Airlines</option>
                                            <option value="CM">Copa Airlines</option>
                                            <option value="SS">Corse Air</option>
                                            <option value="OU">Croatia Airlines</option>
                                            <option value="CY">Cyprus Airways</option>
                                            <option value="OK">Czech Airlines (CSA)</option>
                                            <option value="0D">Darwin Airline</option>
                                            <option value="DX">DAT</option>
                                            <option value="DI">DBA</option>
                                            <option value="DL">Delta Air Lines</option>
                                            <option value="D1">Direct Airlines</option>
                                            <option value="HS">Direkt Flyg</option>
                                            <option value="FZ">Dubai Aviator Corporation, FLyDubai</option>
                                            <option value="9H">Dutch Antilles Airlines</option>
                                            <option value="U2">Easy Jet</option>
                                            <option value="WK">Edelweiss Air</option>
                                            <option value="MS">Egypt Air</option>
                                            <option value="LY">El Al Airlines</option>
                                            <option value="EK">Emirates</option>
                                            <option value="OV">Estonian Air</option>
                                            <option value="ET">Ethiopian Airlines</option>
                                            <option value="EY">Etihad Airways</option>
                                            <option value="3W">Euro Manx</option>
                                            <option value="RY">European Executive Express</option>
                                            <option value="BR">EVA Airways</option>
                                            <option value="XE">Express Jet</option>
                                            <option value="L9">Fairline</option>
                                            <option value="IH">Falcon Air</option>
                                            <option value="AY">Finnair</option>
                                            <option value="FC">Finncomm</option>
                                            <option value="7F">First Air</option>
                                            <option value="8F">Fischer Air</option>
                                            <option value="F8">Flair Airlines</option>
                                            <option value="YC">Flight West Airlines</option>
                                            <option value="BE">Flybe</option>
                                            <option value="SJ">Freedom Air</option>
                                            <option value="F9">Frontier Airlines</option>
                                            <option value="GA">Garuda Airlines</option>
                                            <option value="4U">German Wings</option>
                                            <option value="ST">Germania</option>
                                            <option value="GO">GO Rail</option>
                                            <option value="G3">GOL Linhas Aéreas</option>
                                            <option value="DC">Golden Air</option>
                                            <option value="ZK">Great Lakes Airlines</option>
                                            <option value="GF">Gulf Air</option>
                                            <option value="HU">Hainan Air</option>
                                            <option value="HA">Hawaiian Airlines</option>
                                            <option value="A5">HOP</option>
                                            <option value="QX">Horizon Air</option>
                                            <option value="KH">Horizon Airlines</option>
                                            <option value="61">IBA</option>
                                            <option value="IB">Iberia</option>
                                            <option value="FI">Icelandair</option>
                                            <option value="VQ">Impulse Airlines</option>
                                            <option value="DH">Independence Air</option>
                                            <option value="4O">Interjet Airlines</option>
                                            <option value="3L">InterSky</option>
                                            <option value="EP">Iran Aseman Airlines</option>
                                            <option value="WP">Island Air</option>
                                            <option value="2Q">Italo</option>
                                            <option value="JL">Japan Airlines</option>
                                            <option value="J9">Jazeera Airways</option>
                                            <option value="9W">Jet Airways</option>
                                            <option value="JE">Jet2</option>
                                            <option value="B6">JetBlue Airways</option>
                                            <option value="JQ">Jetstar Airways</option>
                                            <option value="3K">Jetstar Asia Airways</option>
                                            <option value="LJ">Jin Air</option>
                                            <option value="KQ">Kenya Airways</option>
                                            <option value="KP">Kiwi Airlines</option>
                                            <option value="KL">KLM Royal Dutch Airlines</option>
                                            <option value="KE">Korean Airlines</option>
                                            <option value="KU">Kuwait Airways</option>
                                            <option value="LA">LAN</option>
                                            <option value="LR">Lasca</option>
                                            <option value="NG">Lauda Air</option>
                                            <option value="LI">Liat Airline</option>
                                            <option value="TE">Lithuanian Airlines</option>
                                            <option value="LB">Lloyd Aéreo Boliviano</option>
                                            <option value="LO">LOT Polish Airlines</option>
                                            <option value="LT">LTU</option>
                                            <option value="LH">Lufthansa</option>
                                            <option value="LG">Luxair</option>
                                            <option value="MH">Malaysian Airlines</option>
                                            <option value="MA">Malev Airlines</option>
                                            <option value="TF">Malmö Aviation</option>
                                            <option value="MP">Martinair</option>
                                            <option value="IG">Meridiana</option>
                                            <option value="YV">Mesa Airlines</option>
                                            <option value="MX">Mexicana De Aviacion</option>
                                            <option value="ME">Middle East Airlines</option>
                                            <option value="MAV">Minoan Air</option>
                                            <option value="MW">Mokulele Airlines</option>
                                            <option value="ZB">Monarch Airlines</option>
                                            <option value="ZB">Monarch Scheduled</option>
                                            <option value="YM">Montenegro Airlines</option>
                                            <option value="D1">Myrtle Beach Direct Air</option>
                                            <option value="UE">Nasair</option>
                                            <option value="2N">Next Jet</option>
                                            <option value="Z0">NHT</option>
                                            <option value="DD">Nok Air</option>
                                            <option value="LF">Nordic Airlink</option>
                                            <option value="6N">Nordic Regional</option>
                                            <option value="NW">Northwest Airlines</option>
                                            <option value="DY">Norwegian Air Shuttle</option>
                                            <option value="OA">Olympic Airways</option>
                                            <option value="OM">Oman Air</option>
                                            <option value="QO">Origin Pacific Airlines</option>
                                            <option value="ON">Our Airlines</option>
                                            <option value="P3">Passaredo</option>
                                            <option value="PC">Pegasus Airlines</option>
                                            <option value="PR">Philippine Airlines</option>
                                            <option value="PU">PLUNA</option>
                                            <option value="PT">Porter Airlines</option>
                                            <option value="PFL">Private Plane Flights</option>
                                            <option value="QF">Qantas Airways</option>
                                            <option value="QR">Qatar Airways</option>
                                            <option value="QG">Qualiflyer</option>
                                            <option value="RE">Rail Europe</option>
                                            <option value="ZL">Regional Express</option>
                                            <option value="4R">Renfe</option>
                                            <option value="WE">Rhientalflug</option>
                                            <option value="AT">Royal Air Maroc</option>
                                            <option value="QN">Royal Airlines</option>
                                            <option value="BI">Royal Brunei Airlines</option>
                                            <option value="RJ">Royal Jordanian</option>
                                            <option value="FR">Ryanair</option>
                                            <option value="S4">SATA</option>
                                            <option value="SV">Saudia Arabian Airlines</option>
                                            <option value="SK">Scandinavian Airlines (SAS)</option>
                                            <option value="TZ">Scoot Private Limited</option>
                                            <option value="S7">Siberia Airlines</option>
                                            <option value="3M">Silver Airways</option>
                                            <option value="SQ">Singapore Airlines</option>
                                            <option value="XP">Sky Value Airlines</option>
                                            <option value="SX">SkyBus Airlines</option>
                                            <option value="JZ">Skyways</option>
                                            <option value="XR">Skywest Airlines</option>
                                            <option value="6Q">Slovak Airlines</option>
                                            <option value="2C">SNCF</option>
                                            <option value="IE">Solomon Airlines</option>
                                            <option value="S8">Sounds Air</option>
                                            <option value="SA">South African Airways</option>
                                            <option value="WN">Southwest Airlines</option>
                                            <option value="SP">Spanair</option>
                                            <option value="NK">Spirit Airlines</option>
                                            <option value="UL">SriLankan Airlines</option>
                                            <option value="S3">Sta. Bárbara Airlines</option>
                                            <option value="NB">Sterling European Kommanditaktieselskab</option>
                                            <option value="SY">Sun Country Airlines</option>
                                            <option value="WG">Sunwing Airlines</option>
                                            <option value="UR">Surf Air</option>
                                            <option value="PY">Surinam Airways</option>
                                            <option value="LX">SWISS</option>
                                            <option value="TA">TACA</option>
                                            <option value="JJ">TAM Airlines</option>
                                            <option value="TP">TAP Air Portugal</option>
                                            <option value="RO">Tarom Airlines</option>
                                            <option value="TG">Thai Airways</option>
                                            <option value="HQ">Thomas Cook Airlines Belgium</option>
                                            <option value="MT">Thomas Cook Airlines United Kingdom</option>
                                            <option value="BY">Thomson Airways</option>
                                            <option value="TR">Tiger Airways</option>
                                            <option value="UN">Transaero Airlines</option>
                                            <option value="HV">Transavia Airlines</option>
                                            <option value="T9">TransMeridian Airlines</option>
                                            <option value="QS">Travel Service Co</option>
                                            <option value="7T">Trenitalia</option>
                                            <option value="T4">Trip</option>
                                            <option value="X3">TUIfly</option>
                                            <option value="TU">Tunisair</option>
                                            <option value="TK">Turkish Airlines</option>
                                            <option value="VO">Tyrolean Airways</option>
                                            <option value="PS">Ukraine International Airlines</option>
                                            <option value="UA">United Airlines</option>
                                            <option value="U6">Ural Airlines</option>
                                            <option value="U5">USA 3000 Airlines</option>
                                            <option value="VF">Valuair</option>
                                            <option value="RG">Varig SA</option>
                                            <option value="2R">VIA Rail</option>
                                            <option value="VN">Vietnam Airlines</option>
                                            <option value="VX">Virgin America</option>
                                            <option value="VS">Virgin Atlantic Air</option>
                                            <option value="VA">Virgin Australia</option>
                                            <option value="V2">Vision Airlines</option>
                                            <option value="VB">vivaAerobus</option>
                                            <option value="VG">VLM Airlines</option>
                                            <option value="Y4">Volaris Airlines</option>
                                            <option value="V7">Volotea</option>
                                            <option value="VY">VUELING</option>
                                            <option value="XV">WaltAir</option>
                                            <option value="2W">Welcome Air</option>
                                            <option value="WS">WestJet</option>
                                            <option value="WF">Widerøes Flyveselskap ASA</option>
                                            <option value="W6">Wizz Air</option>
                                            <option value="JU">Yugoslav Airlines</option>
                                            <option value="Z4">Zoom Airlines</option>
                                          </select>
*/}
                                            
                                        </div>
                                        <span className="errorLabel">{errors.airline_carrier?.message}</span>
                                      </div>

                                      <div className="col-md-6 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            #Flight
                                          </label>
                                          <input
                                            type="text"
                                            {...register("flight_number")}
                                            className="input-field"
                                          />
                                        </div>
                                        <span className="errorLabel">{errors.flight_number?.message}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-12 hide">
                                    <div className="row">
                                      <div className="col-md-6 col-12 ">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Driver Licence
                                            
                                          </label>
                                          <input
                                            type="text"
                                            {...register("driving_licence_number")}
                                            className="input-field"
                                          />
                                        </div>
                                        <span className="errorLabel">{errors.driving_licence_number?.message}</span>
                                      </div>

                                      <div className="col-md-6 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Exp. Date
                                            
                                          </label>
                                          <DatePicker
                                            selected={selExipryDate}
                                            //onChange={(date: Date) => ref.field.onChange(date.getTime())}
                                            onChange={(date: any) => { SetSelExipryDate(date); }}
                                            dateFormat={"dd/MM/yyyy"}
                                            placeholderText="DD/MM/YYYY"
                                            selectsStart
                                            startDate={selExipryDate}
                                            minDate={new Date()}
                                            className="input-field"
                                          />
                                          <input
                                            type={"hidden"}
                                            {...register("driving_licence_expiration")}
                                            //value={selPickupDate ? moment(new Date(selPickupDate)).format("YYYY-MM-DD") : ""}
                                            defaultValue={selExipryDate ? moment(new Date(selExipryDate)).format("YYYY-MM-DD") : ""}
                                            />
                                        </div>
                                        <span className="errorLabel">{errors.driving_licence_expiration?.message}</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-12">
                                    <div className="row">
                                      <div className={`col-md-6 col-12 ${paymentType == "lc" ? "" : "hide"} `}>
                                        <div className="containerbod bg-grayy">
                                          <label className="label-space-6">
                                            Hertz Gold Plus Reward
                                          </label>
                                          <input
                                            type="text"
                                            {...register("gpnumber")}
                                            className="input-field"
                                            readOnly={true}
                                          />
                                        </div>
                                        <span className="errorLabel">{errors.gpnumber?.message}</span>
                                      </div>
                                      {
                                        selectedVehicelObj.rateObj.is_business_fare === 1 ?
                                      <div className="col-md-6 col-12">
                                        <div className="containerbod bg-grayy">
                                          <label className="label-space-6">
                                              Payment Type
                                          </label>
                                          <input
                                            type="text"
                                            className="input-field"
                                            readOnly={true}
                                            defaultValue={selPaymentType.name}
                                          />

                                          <input
                                            type="hidden"
                                            {...register("payment_type")}
                                            className="input-field"
                                            readOnly={true}
                                          />
                                          
                                        </div>
                                        <span className="errorLabel">{errors.payment_type?.message}</span>
                                      </div>
                                      :
                                      ""
                                      }
                                    </div>
                                  </div>


                                </div>

                                <div className="row">
                                  <div className="col-md-12 col-12">
                                    <div className="row">
                                      <div className="col-md-12 col-12">
                                        <div className="containerbod">
                                          <label className="label-space-6">
                                            Remarks/Booking Ref
                                          </label>
                                          <textarea 
                                          className="textAreaContainer" 
                                          {...register("customer_remarks")}
                                          ></textarea>
                                        </div>
                                        <span className="errorLabel">{errors.customer_remarks?.message}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="infoContainer">
                                      <span className="text-area-nto">
                                        <img src={infoIcon} alt="info icon" onClick={() => setVPopup(true)} />{" "}
                                        Only text and numeric fields are allowed
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="btn-box-container">
                                      <button className="btn-continue-hertz">
                                        Reserve
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        vPopup ?
          <Popupinfo closeHandler={closeHandler} />
          :
          ""
      }
    </>
  );
};

export default CustomerInformation;
