import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getVehicleRates } from '../../external_api/Booking';
//import { login, register } from "../../external_api/User";
import type { RootState } from '../../store';


export const vehiclerates = createAsyncThunk(
  'vehicleAvailable/rates',
  // eslint-disable-next-line no-empty-pattern
  async (_: any, thunkAPI) => {
    console.log("rate response call");
    try {
      
      const response = await getVehicleRates()  
      console.log("rate response",response);
      let { data, status } = response;

     // console.log('rates response', data,status,errorMessage);
      if (status === 201) {
        return { 
          ...data,
          vehicles: data.vehicles, 
          rates: data.rates,  
          
      };
        //return data;
      } else {
        console.log("rate response call");
   
         //return thunkAPI.rejectWithValue(data);
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      console.log('Error', e);
      //@ts-ignore
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



export const vehicleRatesSlice = createSlice({
  name: 'vehicleRates',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    vehicles: [], 
    rates: [],
  },
  reducers: {
    clearVehicleListState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.vehicles = []; 
      state.rates = [];
      return state;
    },
  },
  extraReducers: {
    //@ts-ignore
    [vehiclerates.fulfilled]: (state, { payload }) => {
      console.log('login User payload', payload);
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.vehicles = payload.vehicles; 
      state.rates = payload.rates;
      return state;
    },
    //@ts-ignore
    [vehiclerates.rejected]: (state, { payload }) => {
      console.log('rejected payload', payload);
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      //state.errorMessage = payload.message;
      state.errorMessage = payload.errorMessage
      //state.errorMessage = ""
    },
    //@ts-ignore
    [vehiclerates.pending]: (state) => {
      state.isFetching = true;
    }
  },
});


export const { clearVehicleListState } = vehicleRatesSlice.actions;

//export const userSelector = (state: { user: any; }) => state.user;
export const vehicleRatesSelector = (state: RootState) => state.vehicleAvailable;

export default vehicleRatesSlice.reducer;
