import * as React from "react";
import {useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";


import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
//import { changePassword } from "../../external_api/User";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"


import useSessionCheck from '../../hooks/useSessionCheck';

const PostLoginMenus = ({ menulinks }: {menulinks: any}) => {

  const { isSuccess:isLogin, first_name, role } = useSelector(
    userSelector
  );

  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
    
  const dispatch = useDispatch<AppDispatch>();
    
  const navigate = useNavigate();

  //const timeoutDuration = 30000; // 30 seconds
  const timeoutDuration = 300000; // 5 minutes
  const sessionCheck = useSessionCheck(timeoutDuration)  
  

  const logout = () => {
    dispatch(clearState());
    //toast.success("Logout successfully");
    localStorage.clear();
    navigate("/");
    //setIsLogin(false);
  }

  
  

  const headerNavLinks = menulinks;
  const [menuOpen, setMenuOpen] = useState(false);
  //const [childOpen, setChildOpen] = useState(false);
  const [active, setActive] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
   
  }



  const showChild = () => {
       // setActive(!active);
        setActive(prev => !prev);
        //setActive(prevState => ({...prevState, [index]: !Boolean(prevState[index])}));
    }
  return (
    <>
    <Toaster 

toastOptions={{
    className: 'toaster-popup',
}}
/>
{loading ? (

    <Loader />
) :
    ""
} 
    { isLogin && headerNavLinks.map((data: any) => (
        <div className="main-menu" key={uuidv4()}>
            <div className={`primary-menus ${menuOpen ? 'open' : ''}`}>
            <ul>
              {data.list.map((listData:any) => {
                return (
                  <>
                    
                    {!listData.child ?
                              (
                                listData?.access && listData?.access.includes(role.slug) ?
                                <li key={uuidv4()}>
                                  <Link to={listData.link}>{listData.text}</Link>
                                  </li>
                              :
                              ""
                              )
                              :
                              ""
                            }

                    {listData.child ?
                        (
                          listData?.access && listData?.access.includes(role.slug) ?
                        
                      <>
                      <li key={uuidv4()}>
                      <Link to="#"  onClick={()=>showChild()} className="hasChild addSubchild">{listData.text} <span></span></Link>
                      <div className={`child ${active ? 'open' : ''}`}>
                      <ul>
                        {listData.child.map((child:any,index:number)=>{
                        return (
                            <>
                            {!child.subchild ?
                              (
                                child?.access && child?.access.includes(role.slug) ?
                                <li key={uuidv4()} className="subChildContainer">
                                    {
                                      child.link.indexOf("https") !== -1 ?
                                    <a href={child.link} target="_blank" rel="noreferrer">{child.text}</a>
                                      :    
                                  <Link to={child.link}>{child.text}</Link>
                                    }
                                 </li> 
                              :
                              ""
                              )
                              :
                              ""
                            }
                            {child.subchild &&
                              <>
                              <li key={uuidv4()} className="subChildContainer">
                              <Link to="#" onClick={()=>showChild()} className="hasChild addSubchild">{child.text} <span></span></Link>
                              <div className={`subchild ${active ? 'open' : ''}`}>
                                  <ul>
                                  {child.subchild.map((subchild:any)=>{
                                    return (
                                      <li key={uuidv4()}>
                                        <Link to={subchild.link}>{subchild.text}</Link>
                                      </li>
                                    )
                                  })}

                                  </ul>
                                </div>
                                </li>
                              </>
                            }
                            </>
                        )
                        })}

                      </ul>
                      </div>
                      </li>
                      </>
                        :
                        ""
                        )
                      :
                      ""
                    }
                  </>
                );
              })}
            </ul>
            
            <div className="message">{/* {data.headtext} */}</div>
            <div className="profile-menu">
                <span>
                    {first_name.toUpperCase().substring(0,2)}
                </span>
                {/* <p className="head">{first_name}</p> */}
                <div className="child">
                  <ul>
                    {data.userProfieLinks.map((link:any) => {
                    return (
                      <li key={uuidv4()}>
                        <Link to={link.link}>{link.text}</Link>
                        </li>
                        )
                    })}
                        <li key={uuidv4()}>
                          <a href="#" onClick={logout}>{'Logout'}</a>
                        </li>
                      </ul>
                </div>
            </div>
            </div>
            <div className="hamburger-btn"  onClick={toggleMenu}></div>
        </div>
      ))
    }

    </>
  );
};

export default PostLoginMenus;
