/* import * as React from "react"; */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Titlestrip from "../../utility/Titlestrip";

import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryList } from "../../external_api/User";
import { getCompanyList } from "../../external_api/Company";
import { loginUser, clearState, userSelector } from '../../store/slices/user';
import toast, { Toaster } from 'react-hot-toast';
import { AppDispatch } from "../../store";
import Loader from "../../components/loading/loader"

import { companyTypeList, paymentTypeList, vendorList, privilegeList, paymentTypeRateList, additionalInclusionList } from "../../constants/StaticData";
import { getAirlineDetail, updateAirline } from "../../external_api/Airline";

import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';


const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true
    },
    height: 200 
}


const EditRate = () => {

    const { isFetching, isSuccess: isLogin } = useSelector(
        userSelector
    );

    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    if (!isLogin) {
        navigate("/");
    }




    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        order: Yup.string()
            .required('Order is required'),
        code: Yup.string()
            .required('Code is required'),
        status: Yup.string()
            .required('Status is required'),
    });


    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    type FormValues = {
        name: string,
        code: string,
        order: string,
        status: string
    }   


    const { register, handleSubmit, formState: { errors }, reset, control } = useForm<FormValues>({ mode: "all", resolver: yupResolver(validationSchema) });


    const [loading, setLoading] = useState(false);

    const [airlineDetail, setAirlineDetail] = useState(
        {
            name: "",
            code: "",
            order: "0",
            status: "",
        }
    );
    const paramData = useParams();
    const [airlineId, setAirlineId] = useState(paramData.id);

    //const userId = paramData.

    const [isSubmitForm, setSubmitForm] = useState(false);

    const [loginResMsg, setLoginResMsg] = useState({
        status: false,
        msg: ""
    });

    const [inputUserName, setUserName] = useState("");


    // Login Form Submit Handler

    const onSubmitForm = async (formData: any) => {
       
        setLoading(true);
        var response = await updateAirline(formData, Number(airlineId));
        const { data, status, errorMessage } = response;
        
        if (status == 201) {
            setLoading(false);
            toast.success("Airline has been updated");
            setTimeout(() => {
                navigate("/airline");  
            }, 500);
            //navigate("/rate");
        } else {
            toast.error(errorMessage);
        }
        setLoading(false);

    };

   

    useEffect(() => {
        (async () => {
            setLoading(true);
          

            var response = await getAirlineDetail(airlineId);
            var { data, status } = response;
            
            if (status === 201) {
                setTimeout(() => setAirlineDetail(data), 500);
                //setUserDetail(data);
            } else {
                //navigate("/rate");
            }

            setLoading(false);

            //console.log("profile data",data.output);
        })()

    }, []);

    useEffect(() => {
        return reset(
            {
                name: airlineDetail.name,
                code: airlineDetail.code,
                status: airlineDetail.status,
                order: airlineDetail.order,
                
            }
        );
    }, [reset, airlineDetail]);

    


    return (
        <>
            <Toaster

                toastOptions={{
                    className: 'toaster-popup',
                }}
            />
            {loading ? (

                <Loader />
            ) :
                ""
            }
            <Titlestrip title={"Edit Airline"} />
            
            <div className="form-wrapper container-fluid">
                <div className="row">
                    <div className="container">
                        <form
                            className="spacertopform"
                            onSubmit={handleSubmit(onSubmitForm)}
                            name="userEdit"
                            id="userEdit"
                            autoComplete="off"
                        >
                            {
                                loginResMsg.msg ?
                                    <div className="col-12">
                                        <div className={`alert ${!loginResMsg.status ? "alert-danger set-default-fs" : ""} ${loginResMsg.status ? "alert-success" : ""}`} role="alert">
                                            {loginResMsg.msg}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }

                            <div className="row">
                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>Name<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.name ? 'is-invalid' : ''}`}
                                                {...register("name")}
                                                defaultValue={airlineDetail.name}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.name?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field ">
                                        <label>Code<span className="red">*</span></label>
                                        <div className="text">
                                            <input
                                                type="text"
                                                className={`input-field ${errors.code ? 'is-invalid' : ''}`}
                                                {...register("code")}
                                                defaultValue={airlineDetail.code}
                                            />
                                        </div>
                                    </div>
                                    <span className="formFieldError error">{errors.code?.message}</span>
                                </div>
                                <div className="col-md-2 col-12">
                                    <div className="custom-field">
                                        <label>Status<span className="red">*</span></label>
                                        <div className="select">
                                            <select
                                                {...register("status")}
                                                defaultValue={airlineDetail.status}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-12">
                                    <div className="custom-field ">
                                        <label>Order<span className="red">*</span></label>
                                        <select
                                            {...register("order")}
                                            defaultValue={airlineDetail.order}
                                        >
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                    <span className="formFieldError error">{errors.order?.message}</span>
                                </div>
                              
                            </div>

                          
                            <div className="row mt-4">
                                <div className="col-12">
                                    <input type="submit" value="Save" className="btn-yellow" />
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>

        </>
    )



}


export default EditRate;


