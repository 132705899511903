//@ts-check
//import { stringify } from 'querystring';
import axiosInstance from '../../lib/axios';
import { getAxiosErrorMessage } from '../../utility/object_property_pickers';


const getRateDetail = async (rateId:any)=>{
   let data, status, errorMessage;
  try{
    const response = await axiosInstance.get(`/rates/${rateId}`);
    data = response.data.data;
    status = response.status;
  }catch(error){
    errorMessage = getAxiosErrorMessage(error);
    console.log(errorMessage);
  }
  return {data, status, errorMessage}
}

const getRateList = async ()=>{
    let data, status, errorMessage;
   try{
     const response = await axiosInstance.get(`/rates/all`);
     data = response.data;
     status = response.status;
     if(status !== 200) {
        errorMessage = response.data.message
      }
   }catch(error){
     errorMessage = getAxiosErrorMessage(error);
     //console.log(errorMessage);
   }
   return { data, status, errorMessage }
 }

 const getRates = async (pageNumber:number = 1, pageSize?:number, sort?:string, order?:string, searchTerm?:string) => {
  let data, status, errorMessage;

  try {
    let requestUrl = "/rates?page="+pageNumber;
    if(pageSize) {
      requestUrl = requestUrl + "&page_size=" + pageSize;
    }
    if(sort && order) {
      requestUrl = requestUrl + "&sort=" + sort + ',' + order;
    }
    //console.log("searchTerm", searchTerm);
    if(searchTerm) {
      requestUrl = requestUrl + "&like_or[0]=pcc"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[1]=privilege"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[2]=payment"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[3]=country"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[4]=fare_name"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[5]=cdp"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[6]=rc"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[7]=it_code"+ ',' + searchTerm;
      requestUrl = requestUrl + "&like_or[8]=company"+ ',' + searchTerm;
      
    }
    const response = await axiosInstance.get(requestUrl);
    //console.log("cart List:",response.data);
    data = response.data;    
    status = response.status;
    if(status !== 200) {

      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);

  }
  return { data, status, errorMessage }
}

const addRate = async (RateData: any) => {
  console.log(RateData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.post(`/rates`, RateData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}

const updateRate = async (RateData: any, RateId:number) => {
  console.log(RateData);
  let data, status, errorMessage;

  try {
    const response = await axiosInstance.put(`/rates/${RateId}`, RateData);
    data = response.data.data;
    status = response.status;
    if(status !== 201) {
      errorMessage = response.data.message
    }
  } catch (error) {
    errorMessage = getAxiosErrorMessage(error);
  }
  return { data, status, errorMessage }
}




export {getRateDetail, getRateList, getRates, addRate, updateRate}