import React, { useEffect, useMemo, useState } from "react";
import closeImg from "../../assets/svg/close-modal-hertz-btn.svg";
import carImg from "../../assets/images/car.png";
import carSeats from "../../assets/svg/car-seats.svg";
import carLuggage from "../../assets/svg/car-luggage.svg";
import carAutomatic from "../../assets/svg/car-automatic.svg";
import PopupExtrasCover from "../../components/booking/PopupExtraCover";
import PopupBoxSmall from "../../components/booking/PopupBoxSmall";
import { getExtras } from "../../external_api/Extra";
import { pick } from "lodash";
import { useSelector } from "react-redux";
import { vehicleSelectedRateSelector } from "../../store/slices/selectedVehicle";
import PopupIncluded from "../../components/booking/PopupIncluded";
import PopupToKnow from "../../components/booking/PopupToKnow";

import { coverageList, extrasList } from "../../constants/StaticData";
import { capitalizeFirstLetter, hasCoverageIncluded, hasOptionalCoverage, isElectricVehicle, getDefualtPaymentTypeUser, getExtrasTotal, hasAdditionallDriver, hasYounchDriverSurcharge, hasTankOfFuel, getAdditionalInclusions } from "../../utility/Helpers";
import { useNavigate, useParams } from "react-router-dom";
import { userSelector } from "../../store/slices/user";

const imagePrefix = "https://images.hertz.com/vehicles/220x128/";

const VehiclePopup = ({ rateType, rateVehicle, vPopup, extraData, pickupLocationExtraData, closeHandler }
  : { rateType: string, rateVehicle: any, vPopup: any, extraData: any, pickupLocationExtraData: any, closeHandler: any }) => {

  const { isFetching, isSuccess: isLogin, email: userEmail, phone: userPhone, company, role } = useSelector(
    userSelector
  );
  const navigate = useNavigate();

  const defaultPaymentType = useMemo(() => getDefualtPaymentTypeUser(company.payment_type), [company.payment_type]);

  const paramData = useParams();
  const [reservationId, setReservationId] = useState(paramData.reservationId);

  const searchCoverage = (searchVal: any) => coverageList.filter(element => element.id == searchVal);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [extraCoverPopup, setextraCoverPopup] = useState(false);

  const { isSuccess: isSuccessVehicle, isFetching: isFetchingVehicle, rateObj } = useSelector(
    vehicleSelectedRateSelector
  );

  const continueHandler = () => {
    setVerifyPopup(true);
  };

  const closesmallHandler = () => {
    setVerifyPopup(false);
  };

  const yesHandler = () => {
    setVerifyPopup(false);
    setextraCoverPopup(true);
  };

  const noHandler = () => {
    setVerifyPopup(false);
  };

  const closextraCovelHandler = () => {
    setextraCoverPopup(false);
  };

  const extrasTotalAmount = getExtrasTotal(rateObj.priced_equip)
  console.log("extrasAmount", extrasTotalAmount)
  const searchBooking = String(localStorage.getItem('search_booking'));
  const searchBookingObj = JSON.parse(searchBooking);
  //console.log("searchBookingObj['pickup_oagcode']",searchBookingObj['pickup_oagcode'])
  const [pickupOagcode, setPickupOagcode] = useState(searchBookingObj['pickup_oagcode']);
  const [country, setCountry] = useState(searchBookingObj['country']);

  var transmission_type = rateVehicle.transmission_type ?? "";

  if (transmission_type !== "") {
    transmission_type = transmission_type.charAt(0).toUpperCase() + transmission_type.slice(1);
  }

  const [totalPrice, setTotalPrice] = useState("");



  /* if(rateVehicle.total_price !== totalPrice) {
    console.log("rateVehicle.total_price !== totalPrice", rateVehicle.total_price , totalPrice , rateObj.total_price)
    setTotalPrice(rateVehicle.total_price)
  } */

  const updateParentPrice = (price: any) => {
    setTimeout(() => {
      console.log("updateParentPrice", price)
      setTotalPrice(price);
    }, 5000);

  }
  const closeIncludedHandler = () => {
    setShowIncludedPopup(false)
    setShowToKnowPopup(false)
  }
  const setIncludedPopupHandler = (ratePopup: any) => {
    setShowIncludedPopup(true)
    setRatePopup(ratePopup)
    //console.log("ratePopup", ratePopup)
  }

  const setToKnowPopupHandler = (oagcode: any, ratePopup: any) => {
    setShowToKnowPopup(true)
    setRatePopup(ratePopup)
    //console.log("ratePopup", ratePopup)
  }

  const [showIncludedPopup, setShowIncludedPopup] = useState(false);
  //const [showInclusivePopup , setShowInclusivePopup] = useState(false);
  const [ratePopup, setRatePopup] = useState<any>({});
  const [showToKnowPopup, setShowToKnowPopup] = useState(false);

  const [hasSuperCover, setHasSuperCover] = useState(false);
  const [hasOptionalCover, setHasOptionalCover] = useState(false);

  const hasYounchDriverFeeObj = hasYounchDriverSurcharge(rateObj.fees)

  //@ts-ignore
  const younchDriverFee = hasYounchDriverFeeObj.amount ?? 0;

  return (
    <>
      <div className={`modelPopup_o_c ${vPopup ? "activePopup" : ""} `}>
        <div className="container">
          <div className="modal-pop-up-hertz">
            <button
              className="btn-close-hertz-modal"
              type="button"
              onClick={() => closeHandler()}
            >
              <img src={closeImg} alt={"close image"} />
            </button>
            <div className="modal-inner-hertz">
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <div className="vehicle-image-hertz">
                      {
                        isElectricVehicle(rateVehicle.vehicle_code) &&
                        <div className="vehicle-type-ev">Electric Vehicle</div>
                      }
                      <img
                        src={imagePrefix + rateVehicle.picture_url}
                        alt="Vehicle Image"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="car-detail-hertz">
                      <h2>{rateVehicle.veh_name}</h2>
                      <ul className="list-unstyled">
                        <li>
                          <img src={carSeats} alt={"Car seats"} />
                          <span>{rateVehicle.passener_qty} Seats</span>
                        </li>
                        <li>
                          <img src={carLuggage} alt={"Car Luggage"} />
                          <span>{rateVehicle.baggage_qty} Luggage</span>
                        </li>
                        <li>
                          <img src={carAutomatic} alt={"Car Automatic"} />
                          <span>{transmission_type}</span>
                        </li>
                      </ul>
                      {/* {showIncludedPopup &&
                        <PopupIncluded closeHandler={closeIncludedHandler} rates={ratePopup} />
                      } */}
                      {showToKnowPopup &&
                        <PopupToKnow closeHandler={closeIncludedHandler} oagcode={pickupOagcode} rateId={ratePopup.booking_fare_id} />
                      }

                      {/* <a href="#" onClick={() => setIncludedPopupHandler(rateVehicle)}>Inclusions</a> */}
                      <a 
                      href="#" 
                      onClick={() => setToKnowPopupHandler("oagcode", rateVehicle)}
                      style={{
                        color:"white",
                        backgroundColor:"blue",
                        border: 0
                      }}
                      >
                      Policy
                      </a>
                      {/* <a href="#">To Know</a> */}
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="descripition-details-hertz">
                      <p>{/* { rateVehicle.mileage ? rateVehicle.mileage.replaceAll("_"," ") : ""} */}{" "}</p>
                      <ul className="list-unstyled list-popup-detail-price">

                        <li>
                          <span>Reservation Type</span>
                          <p>{rateType} </p>
                        </li>
                        <li  style={{"display":"none"}}>
                          <span>Pay At location Tax</span>
                          <p>{(Number(rateObj.payAtLocationTax)).toFixed(2)} </p>
                        </li>
                        {
                          rateType == "business" ?
                            <li>
                              <span>Payment Type</span>
                              <p>Partial Value Voucher</p>
                            </li>
                            :
                            ""
                        }
                        <li>

                          <ul className="list-popup-detail-price">
                            {
                              (rateObj?.privilege === "leisure") ?
                                <>
                                  <li>
                                    <span>What You Pay Now</span>
                                    {
                                      Number(rateObj?.pre_pay) == 1 ?
                                        <span>{rateObj?.pre_pay_amount} {' '} {rateVehicle.currency}</span>
                                        :
                                        <span>{(0.00).toFixed(2)}</span>
                                    }
                                  </li>
                                  <li>
                                    <span>Pay at Location</span>
                                    {
                                      Number(rateObj?.pre_pay) == 1 ?
                                        <span>{(Number(rateObj.total_price) - Number(rateObj.pre_pay_amount)).toFixed(2)} {' '} {rateVehicle.currency}</span>
                                        :
                                        <span>{rateObj?.total_price ?? ""} {' '} {rateVehicle.currency}</span>
                                    }
                                  </li>
                                </>
                                :
                                ""
                            }
                            {
                              (rateObj?.privilege === "business" && rateObj?.is_business_fare && defaultPaymentType === "fc") ?
                                <>
                                  <li>
                                    <span>Voucher Amount {" "}{"(Full credit)"}</span>
                                    {
                                      <span>{(Number(rateObj?.total_price) - Number(rateObj?.payAtLocationTax)).toFixed(2) } {' '} {rateVehicle.currency}</span>
                                    }
                                  </li>
                                  <li>
                                    <span>Pay at Location</span>
                                    <span>{(0.00).toFixed(2)}</span>

                                  </li>
                                </>
                                :
                                ""
                            }
                            {
                              (rateObj?.privilege === "business" && rateObj?.is_business_fare && defaultPaymentType === "vl") ?
                                <>
                                  <li>
                                    <span>Voucher Amount {" "}{"(Partial Value Voucher)"}</span>
                                    {

                                      <span>{(Number(rateObj?.total_price) - Number(extrasTotalAmount) - Number(rateObj?.vc_one_way_charge) - Number(younchDriverFee)   - Number(rateObj?.payAtLocationTax)).toFixed(2)} {" "} {rateObj.currency ?? ""}</span>
                                    }

                                  </li>
                                  <li>
                                    <span>Pay at Location</span>
                                    <span>
                                    {/*
                                    {(Number(extrasTotalAmount) + Number(rateObj?.vc_one_way_charge) + Number(younchDriverFee)).toFixed(2)} {" "} {rateObj.currency ?? ""} {"+ Tax"}
                                    */}
                                   {(Number(rateObj?.payAtLocation)).toFixed(2)} {" "} {rateObj.currency ?? ""} 
                                   
                                   {"+ Tax"}
                                    </span>

                                  </li>
                                </>
                                :
                                ""
                            }
                            {
                              (rateObj?.privilege === "business" && rateObj?.is_business_fare && defaultPaymentType === "lc") ?
                                <>
                                  <li>
                                    <span>Voucher Amount {" "}{'(Local charge)'}</span>
                                    {
                                      <span>{(0.00).toFixed(2)}</span>
                                    }
                                  </li>
                                  <li>
                                    <span>Pay at Location</span>
                                    
                                    <span>
                                    {/*
                                    {Number(rateObj?.total_price)} {' '} {rateVehicle.currency}
                                    */}
                                    {(Number(rateObj?.payAtLocation) + Number(rateObj?.payAtLocationTax)).toFixed(2)} {" "} {rateObj.currency ?? ""} 
                                    {rateObj?.payAtLocationTax}
                                    {"+ Tax"}
                                    </span>
                                    


                                  </li>
                                </>
                                :
                                ""
                            }
                            {/* {
                              (rateObj?.privilege === "touroperator")  && role.slug !== "user" ?
                              <>
                                <li>
                                  <span>Tour amount</span>
                                  {
                                      Number(rateObj?.total_price) !== Number(rateObj?.total_price_initial)  ?
                                      <span> {Number(rateObj?.total_price_initial)} {" "} {rateVehicle.currency ?? ""} </span>
                                      :
                                      <span> {" "}  {Number(rateObj?.total_price)} {" "} {rateVehicle.currency ?? ""}</span> 
                                  }
                                  
                                </li>
                                <li>
                                  <span>Pay at Location</span>
                                  {
                                      Number(rateObj?.total_price) !== Number(rateObj?.total_price_initial)  ?
                                      <span>{ (Number(rateObj?.total_price) - Number(rateObj?.total_price_initial)).toFixed(2)} {" "} {rateVehicle.currency ?? ""}</span>
                                      :
                                      <span>{(0.00).toFixed(2)}</span>
                                  }
                                  
                                </li>
                              </>
                              :
                              ""
                            } */}
                            {
                              (rateObj?.privilege === "touroperator") ?
                                <>
                                  <li>
                                    <span>Tour amount</span>
                                    {
                                      <span>
                                      {/*
                                      {(Number(rateObj?.total_price) - Number(extrasTotalAmount) - Number(rateObj?.vc_one_way_charge)   - Number(rateObj?.payAtLocationTax)).toFixed(2) } {" "} {rateObj.currency ?? ""}

                                      {(Number(rateObj?.margin_amount)).toFixed(2) } {" "} {rateObj.currency ?? ""}
                                      */}
                                      {(Number(rateObj?.tour_voucher_amount_with_margin)).toFixed(2) } {" "} {rateObj.currency ?? ""}

                                      </span>
                                    }

                                  </li>
                                  <li>
                                    <span>Pay at Location</span>
                                    {
                                      <span>
                                      {/*
                                      {(Number(extrasTotalAmount) + Number(rateObj?.vc_one_way_charge)).toFixed(2)} {" "} {rateObj.currency ?? ""} {"+ Tax"}
                                      */}
                                     {(Number(rateObj?.payAtLocation) + Number(rateObj?.payAtLocationTax)).toFixed(2)} {" "} {rateObj.currency ?? ""} 

                                      </span>
                                    }

                                  </li>
                                </>
                                :
                                ""
                            }
                            {
                              Number(rateObj?.vc_one_way) == 1 &&
                              <>
                                <li>
                                  <span>One way charges</span>
                                  <span>{rateObj.vc_one_way_charge} {' '} {rateObj.vc_one_way_currency_code} {" + Tax"}</span>
                                </li>

                              </>
                            }
                            {
                              rateObj?.priced_equip?.length > 0 &&
                              <>
                                <li><div className="divider"></div></li>
                                <li><span><b>Extras</b></span></li>

                              </>
                            }

                            {
                              rateObj?.priced_equip?.length > 0 ?
                                rateObj.priced_equip.map((extra: any, index: number) => {
                                  if (Number(extra["Charge"]["@attributes"].Amount) === 0) {
                                    return "";
                                  }
                                  
                                  const extraId = extra["Equipment"]["@attributes"]["EquipType"];
                                  const extraQty = extra["Equipment"]["@attributes"]["Quantity"];
                                  let selExtras = extrasList.filter((item) => {
                                    return extraId == String(item.id);
                                  })
                                  console.log("selExtrasArr", selExtras);
                                  return <li key={index}><span>{capitalizeFirstLetter(selExtras[0]["name"])} (x{extraQty})</span>
                                    <span>{extra["Charge"]["@attributes"].Amount} {" "} {extra["Charge"]["@attributes"].CurrencyCode} {" + Tax"}</span></li>
                                })
                                :
                                ""
                            }

                            <li><div className="divider"></div></li>
                            <li><span><b>Inclusions</b></span></li>

                            <li><span>{capitalizeFirstLetter(rateObj.mileage)}</span><span></span></li>
                            <li><span>{"Total Sales Tax"}</span><span></span></li>
                            {

                              rateObj.priced_coverages ? rateObj.priced_coverages.map((coverage: any, index: number) => {
                                var coverageDetails = searchCoverage(coverage.type);
                                if (!hasCoverageIncluded(coverage)) {
                                  return "";
                                }
                                return <li><span>{coverageDetails[0].name}</span><span></span></li>
                              }
                              )
                                :
                                ""
                            }
                            {/* rateType.toLowerCase() == "flexible plus" */}
                            {/* <li>{country} {" "} {rateType}</li> */}
                            {/*{

                              hasAdditionallDriver(country, rateType) ?
                                <li><span>{"Additional Driver"}</span><span></span></li>
                                :
                                ""

                            }
                            {
                                hasTankOfFuel(country, rateType)  ?
                                <li><span>{"Tank of fuel"}</span><span></span></li>
                                    
                                :
                                ""
                            }*/}

                            {
                                getAdditionalInclusions(country, rateObj?.additional_inclusions)  ?
                                    rateObj?.additional_inclusions.map((name: any) => {
                                        return (name == "Excess" ? (
                                          <li key={name}><span>{name}</span><span>{parseFloat(rateObj?.damage_excess).toFixed(2)} {rateObj?.damage_excess_currency}</span></li>
                                        ) : (
                                          <li key={name}><span>{name}</span><span></span></li>
                                        )
                                      
                                    )})
                                    
                                :
                                ""
                                
                            }

                            {/* Vehicle Licence Fee (VLF)
                            One way fee (payable on return of the vehicle)
                            Total Sales Tax
                            Young Driver Surcharge */}

                            {
                              //@ts-ignore
                              hasYounchDriverFeeObj.purpose &&
                              <>
                                <li><div className="divider"></div></li>
                                <li><span><b>Fees</b></span></li>

                              </>
                            }


                            {
                              rateObj.fees.length > 0 ?
                                rateObj.fees.map((fee: any, index: number) => {
                                  if (Number(fee.amount) === 0 || fee.purpose != 6) {
                                    return "";
                                  }
                                  return <li key={index}><span>{capitalizeFirstLetter(fee.description.replace(":", "   "))}</span>
                                    <span>{fee.amount} {" "} {fee.currency_code} {" + Tax"}</span></li>
                                })
                                :
                                ""
                            }



                            <li><div className="divider"></div></li>
                            <li className="total-amount">
                              <span>Total Estimated</span>
                              {
                                (rateObj?.privilege === "touroperator") ?
                                <span>{parseFloat(rateObj.total_amount_with_margin).toFixed(2)} {' '} {rateVehicle.currency}</span>
                                :
                                <span>{rateObj.total_price} {' '} {rateVehicle.currency}</span>
                              }
                            </li>



                            {

                              hasOptionalCoverage(rateObj) &&
                              <>
                                <li><div className="divider"></div></li>
                                <li><span><b>Optional</b></span></li>
                              </>

                            }

                            {

                              rateObj?.priced_coverages ? rateObj.priced_coverages.map((coverage: any, index: number) => {
                                var coverageDetails = searchCoverage(coverage.type);
                                var coverageIds: number[] = [59];

                                //if (((Number(coverage.amount) === 0 && coverage.unitCharge === "") || (coverageIds.indexOf(coverageDetails[0]?.id) === -1 || hasCoverageIncluded(coverage) ))) {
                                if (hasCoverageIncluded(coverage)) {
                                  return "";
                                }
                                if (!hasOptionalCover)
                                  setHasOptionalCover(true);
                                return (

                                  <li key={index}>
                                    <span>{coverageDetails[0].name}</span>
                                    {
                                      coverage.unitCharge !== "" ?
                                        <span>{coverage.unitCharge} {" "} {coverage?.currency_code} {"per day"} {" + Tax"}</span>
                                        :
                                        <span></span>
                                    }
                                    {
                                      Number(coverage.amount) !== 0 ?
                                        <span>{coverage.amount} {" "} {coverage?.currency_code} {"per day"} {" + Tax"}</span>
                                        :
                                        <span></span>
                                    }
                                  </li>

                                )
                              })
                                :
                                ""
                            }
                            <li><div className="divider"></div></li>
                          </ul>
                          <ul className="list-popup-detail-price inlineList">
                            <li>
                              <button
                                className="black_bg"
                                type="button"
                                onClick={() => continueHandler()}
                              >Add Extras</button>
                            </li>
                            <li style={{
                              alignItems: "flex-end",
                              justifyContent: "flex-end"
                            }}>
                              {
                                reservationId ?

                                  <button
                                    type="button"
                                    onClick={() => {
                                      navigate("/booking/review-book/" + reservationId)
                                    }}
                                  >Continue</button>
                                  :

                                  <button
                                    type="button"
                                    onClick={() => {
                                      navigate("/booking/review-book")
                                    }}
                                  >Continue</button>
                              }

                            </li>

                          </ul>

                        </li>

                        {/* <li>
                          <p>Business AUD 142.48</p>
                          <button type="button">Continue</button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupBoxSmall
        verifyPopup={verifyPopup}
        closesmallHandler={closesmallHandler}
        yesHandler={yesHandler}
        noHandler={noHandler}
      />
      <PopupExtrasCover
        pickupLocationExtraData={pickupLocationExtraData}
        extraData={extraData}
        selVehicle={rateVehicle}
        extraCoverPopup={extraCoverPopup}
        closextraCovelHandler={closextraCovelHandler}
        rateType={rateType}
        updateParentPrice={updateParentPrice}
      />
    </>
  );
};

export default VehiclePopup;
