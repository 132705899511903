const profileList = [
    {"id" : 1, "name": "Business", "code": "business"},
    {"id" : 2, "name": "Tour Operator", "code": "touroperator"},
    {"id" : 4, "name": "Leisure", "code": "leisure"},
    {"id" : 8, "name": "DollarThrifty TOR", "code": "dollarthriftytor"},
    {"id" : 16, "name": "DollarThrifty AdV", "code": "dollarthriftyadv"},
]

const paymentTypeAllList = [   
    {"id" : 1, "name": "Local charge", "code": "LC"},
    {"id" : 2, "name": "Full credit", "code": "FC"},
    {"id" : 4, "name": "Partial Value Voucher", "code": "VL"},
    {"id" : 6, "name": "ITV", "code": "ITV"},
    {"id" : 7, "name": "Prepaid", "code": "Prepaid"},
]

const paymentTypeList = [   
    {"id" : 1, "name": "Local charge", "code": "LC"},
    {"id" : 2, "name": "Full credit", "code": "FC"},
    {"id" : 4, "name": "Partial Value Voucher", "code": "VL"},
    
]

const companyTypeList = [
    {"code": "AGY", "name":"Travel Agency"},
    {"code": "COM", "name": "Company"},
    {"code": "TOR", "name": "Tour Operator"},
    /* {"code": "NET", "name": "Network"},
    {"code": "NEH", "name": "Home Network"} */
]

const vendorList = [
    {"code": "ZE", "name":"Hertz"},
    {"code": "ZR", "name": "Dollar"},
    {"code": "ZT", "name": "Thrifty"},
    {"code": "FF", "name": "Firefly"},
    /* {"code": "HE", "name": "AutoHellas Network"},
    {"code": "AC", "name": "AceRentals"} */
]

const privilegeList = [
    {"code" : "ALL", "name" : "ALL"},
    {"code" : "business", "name" : "business"},
    {"code" : "touroperator", "name" : "touroperator"},
    {"code" : "leisure", "name" : "leisure"},
    {"code" : "dollarthriftytor", "name" : "dollarthriftytor"},
    {"code" : "dollarthriftyadv", "name" : "dollarthriftyadv"},
]

const paymentTypeRateList = [
    { "code": "LC","name": "Local Charge"},
    //{ "code": "BLC","name": "Leisure Local Charge"},
    { "code": "FC","name": "Full Credit"},
    { "code": "VL","name": "Voucher Limited"},
    { "code": "ALL","name": "All"},
    //{ "code": "LIC","name": "Licensee"},
    //{ "code": "LLC","name": "Licensee Local Charge"},
    //{ "code": "SPE","name": "Special"},
]


const extrasList = [
    { "id" : 13, "code": "NAV","name": "GPS Navigator"},
    { "id" : 14, "code": "SKV","name": "Winter Equipment"},
    /* { "id" : 27, "code": "DVD","name": "Wi-Fi Hotspot"}, */
    { "id" : 8, "code": "CST","name": "Child Seat Toddler"},
    /* { "id" : , "code": "CST-2","name": "Extra Child Seat Toddler"}, */
    { "id" : 7, "code": "CSI","name": "Child Seat Infant"},
    /* { "id" : , "code": "CSI-2","name": "Extra Child Seat Infant"}, */
    { "id" : 9, "code": "BST","name": "Child Seat Booster"},
    { "id" : 4, "code": "SKI","name": "Ski Rack"},
    /* { "id" : , "code": "BST-2","name": "Extra Child Seat Booster"}, */
    /* { "id" : 59, "code": "SDW","name": "Super Cover"}, */
    
]

const additionalInclusionList = [   
    {"name": "Additional Driver"},
    /*{"name": "Excess Applies"},*/
    {"name": "Excess"},
    {"name": "Tank of Fuel"},
    {"name": "Reduced Excess"},
    {"name": "GPS"},
    {"name": "SCDW"},
]

/* OTA_CodeType
4 = ski rack	
7 = infant child seat
8 = child toddler seat
9 = booster seat
11 = hand controls right
12 = hand controls left
13 = Hertz NeverLost/portable GPS
14= snow tires
18 = spinner knob
27 = satellite radio
29 = seat belt extender
41 =  Multi Media System
 */



const coverageList = [
    { "id": 7,"code": "CDW","name": "Collision Damage Waiver"},
    { "id": 24,"code": "","name": "Loss damage waiver"},
    { "id": 27,"code": "","name": "Liability insurance supplement"},
    { "id": 32,"code": "","name": "Personal Accident Insurances"},
    { "id": 36,"code": "","name": "Personal Effects Coverage"},
    { "id": 38,"code": "PAI","name": "Personal Accident Insurance"},
    { "id": 40,"code": "SCDWSTP","name": "Super CDW + Super TP Package (SCDW+STP)"},
    { "id": 48,"code": "TP","name": "Theft protection"},
    { "id": 56,"code": "","name": "Max Cover"},
    { "id": 57,"code": "","name": "AER (Accident Excess Reduction) plus"},
    { "id": 58,"code": "","name": "AER (Accident Excess Reduction)"},
    { "id": 59,"code": "SCE","name": "Super Cover"},
    { "id": 49,"code": "SCI","name": "Super Cover Insurance"},
    
]

/* 
edit	1	Collision Damage Waiver	AU,DE,BE,IT,ES,GB,FR,FI,PT,NL	7	CDW.png	CDW	3	2	0	2018-12-11 16:49:22	55	0000-00-00 00:00:00	55
edit	2	Loss damage waiver	DE,IT,ES,GB,FR	24	no-icon.png	NULL	NULL	0	0	2016-05-31 10:12:21	0	0000-00-00 00:00:00	0
edit	3	Liability insurance supplement	DE,IT,ES,GB,FR	27	no-icon.png	NULL	NULL	0	0	2016-05-31 10:12:21	0	0000-00-00 00:00:00	0
edit	4	Personal Accident Insurance	DE,IT,ES,GB,FR,PT	32	PersIns.png		NULL	0	0	2018-12-11 16:48:47	55	0000-00-00 00:00:00	55
edit	5	Personal Effects Coverage	DE,IT,ES,GB,FR	36	no-icon.png		NULL	0	1	2016-07-15 18:48:07	7	0000-00-00 00:00:00	7
edit	6	Personal property insurance	DE,IT,ES,GB,FR	38	PersIns.png	NULL	NULL	0	1	2016-05-31 10:12:21	0	0000-00-00 00:00:00	0
edit	7	Super collision damage waiver	DE,IT,ES,GB,FR	40	CDW.png	CDW	NULL	6	0	2019-06-06 14:55:06	7	0000-00-00 00:00:00	7
edit	8	Theft protection	AU,DE,BE,IT,ES,GB,FR,FI,PT,NL	48	theftprotect.png	TP	3	1	0	2018-12-11 16:48:11	55	0000-00-00 00:00:00	55
edit	9	Max Cover	DE,IT,ES,GB,FR,PT	56	no-icon.png		NULL	0	0	2018-12-11 16:47:59	55	0000-00-00 00:00:00	55
edit	10	AER (Accident Excess Reduction) plus	DE,IT,ES,GB,FR	57	no-icon.png	NULL	NULL	0	0	2016-05-31 10:12:21	0	0000-00-00 00:00:00	0
edit	11	AER (Accident Excess Reduction)	DE,IT,ES,GB,FR	58	no-icon.png	NULL	NULL	0	0	2016-05-31 10:12:21	0	0000-00-00 00:00:00	0
edit	12	Super Cover	AU,DE,BE,IT,GB,FR,FI,NL	59	supercover.png	SCE	1	3	0	2019-06-04 00:16:16	7	0000-00-00 00:00:00	7
edit	14	Super CDW (SCDW)	PT	40	supercover.png	SUPERCDW	1	3	0	2019-06-04 00:29:54	7	2019-06-03 18:59:54	7 */

const countryDefualtCurrency = [
{"name": "New Zealand","country_code":"NZ", "currency_code":"NZD"},
{"name": "Australian","country_code":"AU", "currency_code":"AUD"},
{"name": "Belgium","country_code":"BE", "currency_code":"EUR"},
{"name": "Finland","country_code":"FI", "currency_code":"EUR"},
{"name": "France","country_code":"FR", "currency_code":"EUR"},
{"name": "Germany","country_code":"DE", "currency_code":"EUR"},
{"name": "Ireland","country_code":"IE", "currency_code":"EUR"},
{"name": "Italy","country_code":"IT", "currency_code":"EUR"},
{"name": "Netherlands","country_code":"NL", "currency_code":"EUR"},
{"name": "Portugal","country_code":"PT", "currency_code":"EUR"},
{"name": "Spain","country_code":"ES", "currency_code":"EUR"},
{"name": "United Kingdom","country_code":"GB", "currency_code":"GBP"},
{"name": "United States","country_code":"US", "currency_code":"USD"},
{"name": "Hong Kong","country_code":"HK", "currency_code":"HKD"},
{"name": "Canada","country_code":"CA", "currency_code":"CAD"},
{"name": "Argentina","country_code":"AR", "currency_code":"ARS"},
{"name": "Norway","country_code":"NO", "currency_code":"NOK"},
{"name": "Brazil","country_code":"BR", "currency_code":"BRL"},
{"name": "China","country_code":"CN", "currency_code":"CNY"},
{"name": "Denmark","country_code":"DK", "currency_code":"DKK"},
{"name": "Korea South","country_code":"KR", "currency_code":"KRW"},
{"name": "Switzerland","country_code":"CH", "currency_code":"CHF"},
{"name": "Malta","country_code":"MT", "currency_code":"MTL"},
{"name": "Poland","country_code":"PL", "currency_code":"PLN"},
{"name": "Qatar","country_code":"QA", "currency_code":"QAR"},
{"name": "Singapore","country_code":"SG", "currency_code":"SGD"},
{"name": "Sweden","country_code":"SE", "currency_code":"SEK"},
{"name": "Taiwan","country_code":"TW", "currency_code":"TWD"},
{"name": "United Arab Emirates","country_code":"AE", "currency_code":"AED"},
{"name": "Mexico","country_code":"MX", "currency_code":"USD"}
]

const ageCountryList = [
    { "country_code": "AT",	"age_display" : "25+","min_age":25, "default_age_group":"N", "advisory_required": "N"},    
    { "country_code": "AT",	"age_display" : "21-24","min_age":21, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "AT",	"age_display" : "19-20","min_age":19, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "AU",	"age_display" : "25+","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "AU",	"age_display" : "21-24","min_age":21, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "CA",	"age_display" : "25+","min_age":25, "default_age_group":"Y", "advisory_required": "N"},
    { "country_code": "CA",	"age_display" : "20-24","min_age":20, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "CH",	"age_display" : "25+","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "CH",	"age_display" : "19-24","min_age":19, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DE",	"age_display" : "27+","min_age":27, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DE",	"age_display" : "25-26","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DE",	"age_display" : "21-24","min_age":21, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DK",	"age_display" : "28+","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DK",	"age_display" : "25-27","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "DK",	"age_display" : "23-24","min_age":23, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "DK",	"age_display" : "19-22","min_age":19, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "ES",	"age_display" : "30+ ","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "ES",	"age_display" : "28-29","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "ES",	"age_display" : "25-27","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "ES",	"age_display" : "23-24","min_age":23, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "ES",	"age_display" : "21-22","min_age":21, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FI",	"age_display" : "19+","min_age":19, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FI",	"age_display" : "18","min_age":18, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "FR",	"age_display" : "30+","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FR",	"age_display" : "28-29","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FR",	"age_display" : "25-27","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FR",	"age_display" : "23-24","min_age":23, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "FR",	"age_display" : "21-22","min_age":21, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GB",	"age_display" : "30+","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GB",	"age_display" : "28-29","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GB",	"age_display" : "27","min_age":27, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GB",	"age_display" : "25-26","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GB",	"age_display" : "21-24","min_age":21, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "IT",	"age_display" : "30+","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "IT",	"age_display" : "25-29","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "IT",	"age_display" : "23-24","min_age":23, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "IT",	"age_display" : "21-22","min_age":21, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "NO",	"age_display" : "25+","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "NO",	"age_display" : "19-24","min_age":19, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "PT",	"age_display" : "30+","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "PT",	"age_display" : "25-29","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "PT",	"age_display" : "21-24","min_age":21, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "SE",	"age_display" : "28+","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "SE",	"age_display" : "25-27","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "SE",	"age_display" : "18-24","min_age":18, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "TC",	"age_display" : "35+","min_age":35, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "TC",	"age_display" : "18-34","min_age":18, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "US",	"age_display" : "25+","min_age":25, "default_age_group":"Y", "advisory_required": "N"},
    { "country_code": "US",	"age_display" : "20-24","min_age":20, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "US",	"age_display" : "18-19","min_age":18, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "30+","min_age":30, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "29","min_age":29, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "28","min_age":28, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "27","min_age":27, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "26","min_age":26, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "25","min_age":25, "default_age_group":"N", "advisory_required": "N"},
    { "country_code": "GLOBAL",	"age_display" : "24","min_age":24, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "23","min_age":23, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "22","min_age":22, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "21","min_age":21, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "20","min_age":20, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "19","min_age":19, "default_age_group":"N", "advisory_required": "Y"},
    { "country_code": "GLOBAL",	"age_display" : "18","min_age":18, "default_age_group":"N", "advisory_required": "Y"}
    ]

export {
    profileList, 
    paymentTypeAllList,
    paymentTypeList, 
    companyTypeList, 
    vendorList, 
    privilegeList, 
    paymentTypeRateList, 
    extrasList, 
    coverageList, 
    countryDefualtCurrency,
    ageCountryList,
    additionalInclusionList
}

